// src/layouts/ProjectImageSlider/ProjectImageSlider.js

import React from "react"
import styled from 'styled-components'
import Img from "gatsby-image"
import Slider from "react-slick"

import LongLeftArrow from '../../images/white-arrow-left.svg'
import LongRightArrow from '../../images/white-arrow-right.svg'

const ProjectImageSlider = ({ projectSliderImages }) => {

    const settings = {
        arrows: true,
        dots: true,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
      };

  return (
    <BlockContent
    data-sal="slide-up"
    data-sal-duration="1000"
    data-sal-delay="300"
    data-sal-easing="ease"
    >
        <Slider {...settings}>
            {
            projectSliderImages.map((slide) => (
                <Img fluid={slide.localFile.childImageSharp.fluid} alt={slide.title} />
            ))
            }
        </Slider>
    </BlockContent>
  )
}

const BlockContent = styled.section`
    max-width: 1320px;
    width: 100%;
    margin: 70px auto;
    margin-bottom: 110px;
    padding: 0;
    .slick-list {
        box-shadow: 0 0 20px rgba(0,0,0,.15);
        border: 1px solid #eee;
    }
    .slick-slide {
        > div {
            margin-bottom: -6px;
        }
    }
    .slick-prev {
        width: 50px;
        height: 50px;
        left: -25px;
        background-color: #db4140;
        background-image: url(${LongLeftArrow});
        background-size: 50%;
        background-position: center;
        background-repeat: no-repeat;
        color: transparent;
        z-index: 15;
        transition-duration: .3s;
        &:before {
            display: none;
        }
        &:hover {
          background-color: #2b2520;
          background-image: url(${LongLeftArrow});
        }
    }
      .slick-next {
          width: 50px;
          height: 50px;
          right: -25px;
          background-color: #db4140;
          background-image: url(${LongRightArrow});
          background-size: 50%;
          background-position: center;
          background-repeat: no-repeat;
          color: transparent;
          z-index: 15;
          transition-duration: .3s;
          &:before {
              display: none;
          }
          &:hover {
          background-color: #2b2520;
          background-image: url(${LongRightArrow});
          }
      }
      .slick-dots {
          bottom: -40px;
          z-index: 15;
          li {
              button {
                  width: 20px;
                  height: 20px;
                  background-color: transparent;
                  border: 4px solid #aaa;
                  border-radius: 50%;
                  margin: 0 auto;
                  transition-duration: .3s;
                  &:before {
                      display: none;
                  }
              }
              &.slick-active {
                  button {
                    background-color: #db4140;
                    border-color: #db4140;
                  }
              }
              &:hover {
                  button {
                    background-color: #db4140;
                    border-color: #db4140;
                  }
              }
          }
      }
    @media(max-width:1000px) {
      max-width: 700px;
    }
    @media(max-width:767px) {
      max-width: 500px;
      margin: 50px auto;
      margin-bottom: 70px;
    }
`

export default ProjectImageSlider