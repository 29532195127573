import React from "react"
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

import Layout from "../components/layout-v2"
import SEO from "../components/seo"
import WorkPostBuilder from "../components/work-post-builder"
import ContactUs from "../components/contact-us"

import { FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, TwitterShareButton, TwitterIcon, } from "react-share";

const WorkPostTemplate = ({ data: { workPost, allWorkPosts } }) =>  {

    const layouts = workPost.OurWorkContent.pageBuilder;
    const workPosts = allWorkPosts.edges

    // Load Chance
    const Chance = require('chance');
    // Instantiate Chance so it can be used
    const chance = new Chance();
    //randomly pull two work posts for bottom cta
    const randWorkPosts = chance.pickset(workPosts, 2);
    const twoWorkPosts = randWorkPosts

    return (
        <Layout>
          <SEO 
          title={workPost.SEOmeta.metaTitle} 
          description={workPost.SEOmeta.metaDescription}
          keywords={workPost.SEOmeta.metaKeywords}
          ogTitle={workPost.SEOmeta.ogTitle} 
          ogDescription={workPost.SEOmeta.ogDescription}
          ogImage={workPost.SEOmeta.ogImage.localFile.childImageSharp.fluid}
          twitterTitle={workPost.SEOmeta.twitterTitle} 
          twitterDescription={workPost.SEOmeta.twitterDescription}
          twitterImage={workPost.SEOmeta.twitterImage.localFile.childImageSharp.fluid}
          />
            <article style={{padding: '0 20px', overflow: 'hidden'}}>
                <TitleSection>
                    <h1>{workPost.title}</h1>
                    <p>{workPost.OurWorkSubtitle.headerSubtitle}</p>
                </TitleSection>
                <div>
                    {   
                        layouts.map((layout, index) => {
                            return <WorkPostBuilder key={index} layoutData={layout} />
                        })
                    }
                </div>
                <ShareFlex>
                    <h4>Share</h4>
                    <FacebookShareButton url={`https://wlion.com//work/${workPost.slug}/`} quote={workPost.title} >
                      <FacebookIcon size={64} round={false} iconFillColor={'#2b2520'} />
                    </FacebookShareButton>
                    <LinkedinShareButton url={`https://wlion.com//work/${workPost.slug}/`} title={workPost.title}>
                      <LinkedinIcon size={64} round={false} iconFillColor={'#2b2520'}/>
                    </LinkedinShareButton>
                    <TwitterShareButton url={`https://wlion.com//work/${workPost.slug}/`} title={workPost.title}>
                      <TwitterIcon size={64} round={false} iconFillColor={'#2b2520'}/>
                    </TwitterShareButton>
                </ShareFlex>
                <MoreWorkSection>
                  <h3>View More Work</h3>
                  {twoWorkPosts.map(randomPost => (
                      <article
                      itemScope
                      itemType="http://schema.org/Article"
                      >
                          <Link to={`/work/${randomPost.node.slug}/`} itemProp="url" >
                              {/* <Img fluid={randomPost.node.featuredImage.node.localFile.childImageSharp.fluid} alt={randomPost.node.title} className={"troubleshooting"}/> */}
                              <img src={randomPost.node.featuredImage.node.localFile.childImageSharp.fluid.src} alt={randomPost.node.title} class="normal-image"/>
                          </Link>
                          <Link to={`/work/${randomPost.node.slug}/`}><h4 dangerouslySetInnerHTML={{ __html: randomPost.node.title }} itemProp="headline"/></Link>
                          {/* <p>Website, Ecommerce, Marketing Integration</p> */}
                      </article>
                  ))}
                </MoreWorkSection>
                <ContactUs />
            </article>
        </Layout>
    )

}

const TitleSection = styled.header`
    max-width: 1320px;
    width: 100%;
    padding: 80px 70px;
    margin: 0 auto; 
    margin-top: 20px;
    margin-bottom: 60px;
    border: 10px solid #db4140;
    h1 {
        font-size: 100px;
        margin-top: 0;
        margin-bottom: 0;
    }
    p {
        font-family: "Work Sans";
        font-size: 30px;
        font-weight: 400;
        color: #777c7c;
        margin-bottom: 0;
    }
    @media(max-width:1000px) {
      max-width: 700px;
      padding: 45px;
      h1 {
        font-size: 60px;
      }
      p {
        font-size: 24px;
      }
  }
  @media(max-width: 767px) {
      max-width: 500px;
      padding: 20px;
      margin-bottom: 20px;
      border: 5px solid #db4140;
      h1 {
        font-size: 44px;
      }
      p {
        font-size: 18px;
        line-height: 1.4;
      }
  }
`

const ShareFlex = styled.section`
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    h4 {
        width: 100%;
        font-family: "Work Sans";
        color: #777C7C;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 10px;
        text-align: center;
        text-transform: uppercase;
    }
    button {
      outline: 0;
      margin: 0 10px;
      transition: all .3s cubic-bezier(.77,0,.175,1);
      svg {
        rect {
          fill: transparent;
        }
      }
      &:hover {
        transform: translateY(-2px);
        svg {
          path {
            fill: #db4140;
          }
        }
      }
    }
`

const MoreWorkSection = styled.section`
    max-width: 1320px;
    width: 100%;
    margin: 100px auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    h3 {
        width: 100%;
        text-align: center;
        font-family: "Nobel",sans-serif;
        font-size: 40px;
        line-height: 1.2;
        font-weight: 700;
        color: #2b2520;
    }
    article {
        width: calc(50% - 15px);
        margin-bottom: 40px;
        a {
            text-decoration: none;
            color: #2b2520;
            &:first-child {
              display: inline-block;
              height: 475px;
              width: 100%;
              overflow: hidden;
            }
            > div {
                height: 475px;
                width: 100%;
                background-color: #aaa;
            }
        }
        h4 {
            font-family: "Nobel",sans-serif;
            font-size: 30px;
            line-height: 34px;
            font-weight: 700;
            color: #2b2520;
            margin-top: 10px;
            margin-bottom: 5px;
            transition-duration: .3s;
        }
        p {
            font-family: "Work Sans";
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: #444;
            margin-top: 0;
            margin-bottom: 0px;
        }
        img {
          height: 475px;
          width: 100%;
          object-fit: cover;
          transition: opacity 500ms ease 0s, transform 1s 0s !important;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
          h4 {
              color: #db4140;
          }
      }
    }
    @media(max-width:1000px) {
      max-width: 700px;
      article {
        width: 100%;
      }
    }
    @media(max-width: 767px) {
      max-width: 500px;
      article {
        a {
          &:first-child {
            height: 275px;
          }
          > div {
            height: 275px;
          }
        }
        img {
          height: 275px;
        }
        h2 {
          font-size: 24px;
          line-height: 1.2;
        }
      }
    }
`

export default WorkPostTemplate

export const pageQuery = graphql`
  query WorkPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting all workposts minus current post
    allWorkPosts: allWpOurWorkProject(filter: {id: {ne: $id}}) {
      edges {
        node {
            title
            slug
            databaseId
            featuredImage {
                node {
                  localFile {
                      childImageSharp {
                        fluid(maxWidth: 750, quality: 100) {
                            src
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                  }
                }
            }
        }
      }
    }
    # selecting the current work post by id
    workPost: wpOurWorkProject(id: { eq: $id }) {
      id
      content
      title
      slug
      databaseId
      date(formatString: "MMMM DD, YYYY")
      OurWorkSubtitle {
        headerSubtitle
      }
      SEOmeta {
        metaTitle
        metaDescription
        metaKeywords
        ogTitle
        ogDescription
        ogImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1320, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1320, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      OurWorkContent {
        pageBuilder {
            __typename
            ... on WpOurWorkProject_Ourworkcontent_PageBuilder_WorkContentBlock {
                fieldGroupName
                textContent
            }
            ... on WpOurWorkProject_Ourworkcontent_PageBuilder_WorkContentBlockWithAccent {
                fieldGroupName
                textContentWithAccent
              }
            ... on WpOurWorkProject_Ourworkcontent_PageBuilder_FullWidthImage {
                fieldGroupName
                imageUpload {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1320, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
            ... on WpOurWorkProject_Ourworkcontent_PageBuilder_TwoColumnImages {
                fieldGroupName
                firstImage {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 800, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                secondImage {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 800, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
            ... on WpOurWorkProject_Ourworkcontent_PageBuilder_ProjectTestimonial {
                fieldGroupName
                testimonialQuote
                testimonialAuthor
                authorInfo
            }
            ... on WpOurWorkProject_Ourworkcontent_PageBuilder_ProjectStats {
                fieldGroupName
                statOneInfo
                statOneIcon {
                  localFile {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                  }
                }
                statOneNumber
                statOnePercentage
                statOneTitle
                statThreeIcon {
                  localFile {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                  }
                }
                statThreeInfo
                statThreeNumber
                statThreePercentage
                statThreeTitle
                statTwoIcon {
                  localFile {
                    childImageSharp {
                        fluid(maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                  }
                }
                statTwoInfo
                statTwoNumber
                statTwoPercentage
                statTwoTitle
              }
              ... on WpOurWorkProject_Ourworkcontent_PageBuilder_ProjectButton {
                fieldGroupName
                projectButtonCopy
                projectButtonLink
              }
              ... on WpOurWorkProject_Ourworkcontent_PageBuilder_ProjectImageSlider {
                fieldGroupName
                projectSliderImages {
                  title
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1320, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
        }
      }
    }
  }
`