// src/layouts/ProjectButton/ProjectButton.js

import React from "react"
import styled from 'styled-components'

import { BsArrowRight } from 'react-icons/bs'
import SmallArrow from '../../svgs/small-arrow.svg'

const ProjectButton = ({ projectButtonLink, projectButtonCopy }) => {

  return (
    <BlockContent
      data-sal="slide-up"
      data-sal-duration="1000"
      data-sal-delay="300"
      data-sal-easing="ease"
    >
        <p><a href={projectButtonLink}>{projectButtonCopy} <SmallArrow/></a></p>
    </BlockContent>
  )
}

const BlockContent = styled.section`
    max-width: 1360px;
    width: 100%;
    margin: 50px auto;
    padding: 0 20px;
    p {
      text-align: center;
      margin: 0;
      a {
        font-family: "Work Sans";
        font-size: 20px;
        line-height: 60px;
        font-weight: 700;
        color: #2b2520;
        margin-top: 0;
        margin-bottom: 10px;
        text-decoration: none;
        border: 3px solid #db4140;
        display: block;
        max-width: 300px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 24px;
          color: #db4140;
          margin-left: 10px;
          transition: all .3s cubic-bezier(.77,0,.175,1);
        }
        &:hover {
          color: #fff !important;
          background-color: #db4140;
          svg {
            transform: translateX(10px);
            path {
              fill: #fff;
            }
          }
        }
      }
    }
`

export default ProjectButton