// src/components/work-post-builder.js

import React from "react"
import ContentBlock from "../layouts/ContentBlock"
import ContentBlockWithAccent from "../layouts/ContentBlockWithAccent"
import FullImageBlock from "../layouts/FullImageBlock"
import TwoColumnImagesBlock from "../layouts/TwoColumnImagesBlock"
import ProjectTestimonial from "../layouts/ProjectTestimonial"
import ProjectStats from "../layouts/ProjectStats"
import ProjectButton from "../layouts/ProjectButton"
import ProjectImageSlider from "../layouts/ProjectImageSlider"

const WorkPostBuilder = ({ layoutData }) => {

  const layoutType = layoutData.fieldGroupName

  /**
   * Default component
   */
  const Default = () => <div>In AllLayouts the mapping of this component is missing: {layoutType}</div>

  /**
   * Mapping the fieldGroupName(s) to our components
   */
  const layouts = {
    OurWorkProject_Ourworkcontent_PageBuilder_WorkContentBlock: ContentBlock,
    OurWorkProject_Ourworkcontent_PageBuilder_WorkContentBlockWithAccent: ContentBlockWithAccent,
    OurWorkProject_Ourworkcontent_PageBuilder_FullWidthImage: FullImageBlock,
    OurWorkProject_Ourworkcontent_PageBuilder_TwoColumnImages: TwoColumnImagesBlock,
    OurWorkProject_Ourworkcontent_PageBuilder_ProjectTestimonial: ProjectTestimonial,
    OurWorkProject_Ourworkcontent_PageBuilder_ProjectStats: ProjectStats,
    OurWorkProject_Ourworkcontent_PageBuilder_ProjectButton: ProjectButton,
    OurWorkProject_Ourworkcontent_PageBuilder_ProjectImageSlider: ProjectImageSlider,
    page_default: Default
  }

  /**
   * If layout type is not existing in our mapping, it shows our Default instead.
   */
  const ComponentTag = layouts[layoutType] ? layouts[layoutType] : layouts['page_default']

  return (
    <ComponentTag {...layoutData} />
  )
}

export default WorkPostBuilder