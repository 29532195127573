// src/layouts/TwoColumnImagesBlock/TwoColumnImagesBlock.js

import React from "react"
import styled from 'styled-components'
import Img from "gatsby-image"

const ContentBlock = ({ firstImage, secondImage }) => {

  return (
    <BlockContent>
        <div
        data-sal="slide-right"
        data-sal-duration="1000"
        data-sal-delay="300"
        data-sal-easing="ease"
        >
          <Img fluid={firstImage.localFile.childImageSharp.fluid} alt={""} />
        </div>
        <div
        data-sal="slide-left"
        data-sal-duration="1000"
        data-sal-delay="600"
        data-sal-easing="ease"
        >
          <Img fluid={secondImage.localFile.childImageSharp.fluid} alt={""} />
        </div>
    </BlockContent>
  )
}

const BlockContent = styled.section`
    max-width: 1360px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      width: calc(50% - 10px);
    }
    @media(max-width:1000px) {
      max-width: 700px;
      flex-wrap: wrap;
      > div {
        width: 100%;
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
    @media(max-width:767px) {
      max-width: 500px;
      padding: 0;
      margin: 20px auto;
    }
`

export default ContentBlock