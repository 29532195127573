// src/layouts/ProjectStats/ProjectStats.js

import React from "react"
import styled from 'styled-components'
import Img from "gatsby-image"

//Add Schema??

const ProjectStats = ({ statOneIcon, statOneNumber, statOnePercentage, statOneTitle, statOneInfo, statTwoIcon, statTwoNumber, statTwoPercentage, statTwoTitle, statTwoInfo, statThreeIcon, statThreeNumber, statThreePercentage, statThreeTitle, statThreeInfo }) => {

  return (
    <BlockContent>
        <div
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-delay="300"
        data-sal-easing="ease"
        >
            <Img fluid={statOneIcon.localFile.childImageSharp.fluid} alt={statOneTitle} />
            <h3>{statOneNumber}{statOnePercentage === true ? "%" : ""}</h3>
            <h4>{statOneTitle}</h4>
            <p>{statOneInfo}</p>
        </div>
        <div
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-delay="600"
        data-sal-easing="ease"
        >
            <Img fluid={statTwoIcon.localFile.childImageSharp.fluid} alt={statTwoTitle} />
            <h3>{statTwoNumber}{statTwoPercentage === true ? "%" : ""}</h3>
            <h4>{statTwoTitle}</h4>
            <p>{statTwoInfo}</p>
        </div>
        <div
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-delay="900"
        data-sal-easing="ease"
        >
            <Img fluid={statThreeIcon.localFile.childImageSharp.fluid} alt={statThreeTitle} />
            <h3>{statThreeNumber}{statThreePercentage === true ? "%" : ""}</h3>
            <h4>{statThreeTitle}</h4>
            <p>{statThreeInfo}</p>
        </div>
    </BlockContent>
  )
}

const BlockContent = styled.section`
    max-width: 1360px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 50px;
    padding: 50px 20px;
    display: flex;
    justify-content: space-between;
    > div {
        width: calc(33.33% - 15px);
        border: 10px solid #e2e2e2;
        padding: 50px;
        text-align: center;
        .gatsby-image-wrapper {
            max-width: 125px;
            width: 100%;
            margin: 0 auto;
            margin-bottom: 35px;
        }
        h3 {
            font-family: "Nobel",sans-serif;
            color: #2b2520;
            font-size: 70px;
            line-height: 1;
            font-weight: 700;
            font-style: normal;
            margin-top: 0;
            margin-bottom: 0;
        }
        h4 {
            font-family: "Work Sans";
            font-size: 24px;
            line-height: 38px;
            font-weight: 700;
            color: #db4140;
            margin-top: 0;
            margin-bottom: 10px;
        }
        p {
            font-family: "Work Sans";
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            color: #777c7c;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    @media(max-width:1000px) {
        max-width: 700px;
        flex-wrap: wrap;
        justify-content: center;
        > div {
            width: calc(50% - 30px);
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 30px;
            padding: 30px;
            h3 {
                font-size: 44px;
            }
        }
    }
    @media(max-width:767px) {
        max-width: 500px;
        > div {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 30px;
            padding: 30px 20px;
            h3 {
                font-size: 32px;
                margin-bottom: 5px;
            }
            h4 {
                font-size: 20px;
                line-height: 1.3;
            }
        }
    }
`

export default ProjectStats